import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button, Table, Modal } from "antd";
import { http } from "../../../../services/http";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import ShipProductModalEdit from "../../../../components/WhrManager/shipProductEdit";
import { getFiveDaysAgo, getToday } from "../../../../utils/getDays";
import "./ShipProductStyle.css";

const ShipProduct = ({ datas, onClose, visible }) => {
  const { t } = useTranslation();
  const employeeId = useSelector((state) => state.main.employeeId);
  const [file, setFile] = useState(null);
  const [path, setPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const today = getToday();
  const fiveDaysAgo = getFiveDaysAgo();

  const [date, setDate] = useState(today);

  const [loadingData, setLoadingData] = useState({
    ddata: false,
    wdata: false,
    mdata: false,
  });

  const [data, setData] = useState({
    ddata: [],
    value: "",
    wdata: [],
    mdata: [],
    managerId: "",
  });

  const { ddata, value, wdata, mdata, managerId } = data;
  const defaultWarehouse = "Angr-sk"; // default warehouse code

  const initialSelectedWarehouses = datas.whsCode.reduce((acc, code, index) => {
    acc[index] = defaultWarehouse; // use provided code or default to Angr-sk
    return acc;
  }, {});

  const [selectedWarehouses, setSelectedWarehouses] = useState(
    initialSelectedWarehouses,
  );

  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");

  const handleSelectClick = () => {
    setSelectedComments(data.comments);
    setIsCommentModalVisible(true);
  };

  const handleModalOk = () => {
    setIsCommentModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsCommentModalVisible(false);
  };

  const handleWarehouseChange = (value, rowIndex) => {
    const selectedWarehouse = wdata.find(
      (warehouse) => warehouse.code === value,
    );
    const warehouseName = selectedWarehouse ? selectedWarehouse.code : "";
    setSelectedWarehouses((prevSelectedWarehouses) => ({
      ...prevSelectedWarehouses,
      [rowIndex]: warehouseName,
    }));
  };
  useEffect(() => {
    const fetchAllData = async () => {
      setLoadingData({ ddata: true, wdata: true, mdata: true });
      await Promise.all([fetchDrivers(), fetchWarehouse(), fetchWhsManagers()]);
      setLoadingData({ ddata: false, wdata: false, mdata: false });
    };
    fetchAllData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  useEffect(() => {
    const handleBeforePrint = () => {
      handleSaveAsPDF(true);
    };

    window.addEventListener("beforeprint", handleBeforePrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
    };
  }, []);

  const fetchDrivers = async () => {
    try {
      const { data } = await http.get(`api/drivers`);

      const formattedData = data.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        value: item.employeeID,
      }));

      setData((prevState) => ({
        ...prevState,
        ddata: formattedData,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWhsManagers = async () => {
    try {
      const { data } = await http.get(`api/whsmanagers`);

      const formattedData = data.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        value: item.employeeID,
      }));

      setData((prevState) => ({
        ...prevState,
        mdata: formattedData,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data
        .filter((item) => item.warehouseCode !== "01")
        .map((item) => ({
          code: item.warehouseCode,
          name: item.warehouseName,
        }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDriverChange = (value) => {
    const selectedItem = ddata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      value: selectedItem.value,
    }));
  };

  const handleManagerChange = (value) => {
    const selectedItem = mdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      managerId: selectedItem.value,
    }));
  };

  const postData = async () => {
    if (value && managerId) {
      setLoading(true);

      try {
        const documentLines = datas.maxsulot
          .map((item, index) => ({
            baseEntry: datas.docEntry,
            baseLine: datas.lineNum[index],
            itemCode: datas.itemCode[index],
            quantity: datas.quantity[index],
            warehouseCode: selectedWarehouses[index],
            unitPrice: datas.price[index],
            currency: datas.currency[index],
          }))
          .filter((_, index) => datas.lineStatus[index] !== "C");

        await http.post("api/ortibyuborish", {
          cardCode: String(datas.cardCode),
          u_Driver: Number(value),
          u_Father_DocNum: datas.docNum,
          u_Responsible_Person: managerId,
          slpCode: datas.salesPersonCode !== "Нет" ? datas.salesPersonCode : -1,
          documentsOwner: employeeId,
          docDueDate: date,
          docDate: date,
          documentLines: documentLines,
        });
        message.success(t("sucCreated"));

        setTimeout(() => {
          resetForm();
          onClose();
        }, 800);
      } catch (error) {
        console.log("Error");
        message.error(t("errCreation") + error.message);
        console.log("Error:", error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("selectDriver"));
    }
  };

  const onEdit = async () => {
    setIsModalVisible(true);
  };

  const uploadImg = async () => {
    if (!file) {
      message.error(t("fileSelected"));
      return;
    }

    const formData = new FormData();
    formData.append("formFile", file);
    try {
      setImgLoading(true);
      const res = await http.post("api/fileupload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { createdAt, path } = res.data;
      setPath(path);

      message.success(t("sucUpload"));
    } catch (err) {
      message.error(t("errUpload"));
    } finally {
      setImgLoading(false);
    }
  };

  const viewImage = () => {
    const fullUrl = `https://dotnet-csharp.uz/${path}`;
    window.open(fullUrl, "_blank");
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      value: "",
      managerId: "",
    }));
  };

  const handleSaveAsPDF = (isPrintEvent = false) => {
    const input = document.getElementById("ship-product-form");
    const selects = input.querySelectorAll(".ant-select-selection-item");
    selects.forEach((select) => {
      select.style.paddingTop = "20px";
      select.style.paddingBottom = "20px";
    });

    html2canvas(input, { scrollY: window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 180;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("ship-product-form.pdf");

      if (isPrintEvent) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    });
  };

  const sum =
    datas.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(datas.docTotalFC)
      : new Intl.NumberFormat("fr-FR").format(datas.summa);

  const columns = [
    {
      title: t("item"),
      dataIndex: "maxsulot",
      key: "maxsulot",
      width: 280,
      render: (text, record, index) => {
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {formattedText}
          </span>
        );
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
      render: (text, record, index) => {
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {formattedText} {record.currency}
          </span>
        );
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "inventoryQuantity",
      key: "inventoryQuantity",
      render: (text, record, index) => {
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {text}
          </span>
        );
      },
    },

    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
      render: (text, record, index) => {
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record, index) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${datas.lineStatus[index] === "C" ? "text-lg font-extrabold text-slate-500" : "text-lg font-extrabold text-black"}`}
          >
            {`${formatSum} ${record.currency}`}
          </span>
        );
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
      render: (text, record, index) => (
        <Select
          value={selectedWarehouses[index]}
          onChange={(value) => handleWarehouseChange(value, index)}
          className="flex h-12 w-[200px] items-center justify-center text-center text-lg font-extrabold text-black"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          disabled={datas.lineStatus[index] === "C"}
        >
          {wdata
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((warehouse) => (
              <Select.Option key={warehouse.code} value={warehouse.code}>
                {warehouse.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
  ];

  const tableData = datas.maxsulot.map((item, index) => ({
    key: index,
    maxsulot: item,
    quantity: datas.quantity[index],
    measureUnit: datas.measureUnit[index],
    price: datas.price[index],
    inventoryQuantity: datas.inventoryQuantity[index],
    lineTotal: datas.lineTotal[index],
    whsName: selectedWarehouses[index],
    ugpName: datas.ugpName[index],
    currency: datas.currency[index],
    totalFrgn: datas.totalFrgn[index],
  }));

  return (
    <div className="w-full">
      <div id="ship-product-form" className="w-full">
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("mijoz")}</p>
            <Input
              type="text"
              value={datas.mijoz}
              className="flex h-12 w-full items-center justify-center font-bold text-black sm:w-[250px]"
              style={{ color: "black" }}
              disabled
            />
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("date")}</p>

            <Input
              type="date"
              value={date}
              className="flex w-full items-center justify-center font-bold text-black sm:w-[120px]"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              max={today}
              min={fiveDaysAgo}
            />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-12 border-black"
        />

        <div className="mt-10 flex items-center justify-between">
          <div className="flex flex-col gap-3">
            <p>{t("comments")}</p>
            <Select
              value={data.comments}
              className="flex h-12 w-full items-center justify-center font-bold text-black sm:w-[250px]"
              style={{ color: "black" }}
              disabled
              onClick={handleSelectClick}
            />
            <Modal
              title={t("comments")}
              visible={isCommentModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
            >
              <p>{selectedComments}</p>
            </Modal>
          </div>
          <div className="flex items-center justify-end gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("docTotalQuantity")}</p>
              <Input
                type="text"
                value={`${datas.docTotalQuantity} кг`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("totalPrice")}</p>
              <Input
                type="text"
                value={`${sum} ${datas.docCur}`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex flex-col gap-3">
            <p>{t("responsiblePerson")}</p>
            <Spin spinning={loadingData.mdata}>
              <Select
                value={
                  managerId
                    ? mdata.find((item) => item.value === value)?.name
                    : undefined
                }
                placeholder={t("chooseManager")}
                onChange={handleManagerChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-14 w-full items-center justify-center sm:w-[300px]"
              >
                {mdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.value} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p>{t("driver")}</p>
            <Spin spinning={loadingData.ddata}>
              <Select
                value={
                  value
                    ? ddata.find((item) => item.value === value)?.name
                    : undefined
                }
                placeholder={t("chooseDriver")}
                onChange={handleDriverChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-14 w-full items-center justify-center sm:w-[300px]"
              >
                {ddata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.value} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="mt-20 flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
          <Button
            onClick={postData}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
            disabled={loading}
            loading={loading}
          >
            {t("ship")}
          </Button>
          <div className="flex w-full flex-col items-center gap-3 sm:w-auto sm:flex-row">
            <Input
              type="file"
              onChange={handleFileChange}
              className="w-full sm:w-auto"
            />
            <Button
              onClick={uploadImg}
              loading={imgLoading}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
            >
              {imgLoading ? t("uploading") : t("uploadImg")}
            </Button>
          </div>

          {path && (
            <Button
              onClick={viewImage}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
            >
              {t("viewImg")}
            </Button>
          )}
          <Button
            onClick={onEdit}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
          >
            {t("edit")}
          </Button>
          <Button
            onClick={() => handleSaveAsPDF(false)}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[300px]"
          >
            {t("savePDF")}
          </Button>
        </div>
      </div>
      <ShipProductModalEdit
        visible={isModalVisible}
        data={datas}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default ShipProduct;
